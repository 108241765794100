import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import ColorAnalyzer from '../pages/ColorAnalyzer';
import ColorTheory from '../pages/ColorTheory';
import PalettesContent from '../pages/PalettesContent';

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/inicio" />} />
            <Route path="/inicio" element={<LandingPage />} />
            <Route path="/entenda" element={<ColorTheory />} />
            {/* <Route path="/cartela-inverno" element={<WinterPaletteContent />} />
            <Route path="/cartela-primavera" element={<SpringPaletteContent />} />
            <Route path="/cartela-verao" element={<SummerPaletteContent />} />
            <Route path="/cartela-outono" element={<AutumnPaletteContent />} /> */}
            <Route path="/analise-cor" element={<ColorAnalyzer />} />
            <Route path="/cartelas" element={<PalettesContent />} />
        </Routes>
    );
}

export default AppRoutes;