import logo from '../assets/logo-mycolorai.png';

export const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-[#BCBCBC] text-sm py-10 text-center">
      <div className="container">
        <div className="inline-flex relative before:content-[''] before:top-0 before:bottom-0 before:w-full before:blur before:bg-[linear-gradient(to_right,#F87BFF,#FB92CF,#FFDD9B,#C2F0B1)] before:absolute">
          <img src={logo} alt="Logo" height={40} width={40} className="relative rounded-lg" />
        </div>
        {/* <nav className="flex flex-col md:flex-row md:justify-center gap-6 mt-6">
          <a href="/">Sobre nós</a>
          <a href="/">Produtos</a>
          <a href="/">Clientes</a>
          <a href="/">Atualizações</a>
          <a href="/">Ajuda</a>
        </nav> */}
        <p className="mt-6">&copy; 2024 guiadascores.com.br</p>
      </div>
    </footer>
  )
};
