import React from "react";
import { Link } from "react-router-dom";
import productShowcaseMd from "../assets/product_showcase_1.jpg";
import productShowcaseMs from "../assets/product_showcase_2.jpg";

export const ProductShowcase: React.FC = () => {
  return (
    <section id="showcase" className="bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] py-24 overflow-x-clip">
      <div className="container">
        <div className="max-w-[720px] mx-auto">
          <h2 className="text-center text-3xl md:text-[54px] md:leading-[60px] font-bold tracking-tighter bg-gradient-to-b from-black to-teal-700 text-transparent bg-clip-text">
            Transforme Seu Visual com Cores que Valorizam Sua Beleza
          </h2>
          <p className="text-center text-[22px] leading-[30px] tracking-tight text-[#333333] mt-5">
            Use as tonalidades mais indicadas para seu tom de pele, sinta-se confiante e arrase em cada ocasião. Eleve seu estilo e realce o melhor de você.
          </p>
        </div>
        <div className="relative flex justify-center">
          <img src={productShowcaseMd} alt="Product web" className="border border-[#000]/80 mt-10 rounded-lg hidden md:block"/>
          <img src={productShowcaseMs} alt="Product mobile" className="border border-[#000]/80 mt-10 rounded-lg md:hidden"/>
        </div>
        <div className="flex justify-center mt-10">
          <Link to="/analise-cor" className="btn-call-to-action">
            Clique aqui e faça sua análise agora
          </Link>
        </div>
      </div>
    </section>
  )
};