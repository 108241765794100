// src/pages/ColorAnalyzer.tsx

import { CallToAction } from "../sections/CallToAction";
import { Footer } from "../sections/Footer"
import { Header } from "../sections/Header"

const ColorAnalyzer: React.FC = () => {
    return (
        <>
            <Header />
            <CallToAction />
            <Footer />
        </>
    );
};

export default ColorAnalyzer;