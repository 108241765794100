// src/pages/Home.tsx
import React from 'react';
import { Header } from '../sections/Header';
import { Hero } from '../sections/Hero';
import { ProductShowcase } from '../sections/ProductShowcase';
import { Footer } from '../sections/Footer';
import { CallToAction } from '../sections/CallToAction';

const LandingPage: React.FC = () => {
  return (
    <>
      <Header />
      <Hero />
      <ProductShowcase />
      <Footer />
    </>
  );
};

export default LandingPage;
