// src/SeasonColorsClient.ts
import axios from 'axios';
import { ColorPaletteResponse } from './types'; // Adjust the import path as necessary

// Retrieve the API URL from an environment variable or fallback to a default value
const SEASON_COLORS_API_URL = 'https://my-color-ai-api-223673456302.southamerica-east1.run.app/v1/seasoncolors';
//const SEASON_COLORS_API_URL = 'http://localhost:8080/v1/seasoncolors';

export class SeasonColorsClient {
    public static async fetchSeasonColors(
        colorR: number,
        colorG: number,
        colorB: number
    ): Promise<ColorPaletteResponse | null> {
        try {
            console.log(colorR, colorG, colorB);
            const response = await axios.get<ColorPaletteResponse>(SEASON_COLORS_API_URL, {
                params: {
                    color_r: colorR,
                    color_g: colorG,
                    color_b: colorB
                }
            });

            return response.data;
        } catch (error) {
            console.error('Error fetching season colors:', error);
            return null;
        }
    }
}
