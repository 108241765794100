// src/pages/ContentPage.tsx
import React from 'react';
import { Header } from '../sections/Header';
import { Footer } from '../sections/Footer';

const PalettesContent: React.FC = () => {
  return (
    <>
      <Header />
      <section className="bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] py-24 overflow-x-clip">
        <div className="container">
          <div className="max-w-[720px] mx-auto">
            <h2 className="text-center text-3xl md:text-[54px] md:leading-[60px] font-bold tracking-tighter bg-gradient-to-b from-black via-gray-700 to-teal-800 text-transparent bg-clip-text">
              Inverno
            </h2>
            <p className="text-center text-lg leading-[28px] font-bold tracking-tight text-[#333333] mt-5">
              Sendo completamente fria e brilhante, essa cartela possui muitas cores mais escuras (porque o azul puro é frio e escuro). O Inverno é a estação de alto contraste e alta intensidade.
              Consequentemente, não encontramos apenas sombras, mas também tonalidades nesta estação.
            </p>
          </div>
          <div className="max-w-[720px] mx-auto mt-16">
            <h3 className="text-2xl md:text-3xl font-semibold text-[#333333] mb-6">
              Características da Cartela de Cores de Inverno
            </h3>
            <ul className="list-disc text-justify md:text-left list-inside mb-6 text-[#333333] text-lg leading-[28px]">
              <li>
                <strong>Tons Frios e Neutros:</strong> As cores de inverno geralmente incluem tons frios e neutros, como o azul marinho, cinza, preto e branco. Essas cores remetem ao céu nublado e ao gelo, trazendo uma sensação de tranquilidade e sofisticação.
              </li>
              <li>
                <strong>Cores Profundas e Ricas:</strong> Tons mais profundos, como o verde-escuro, vinho e roxo, são comuns na cartela de inverno. Essas cores são associadas à riqueza e à intensidade da estação, oferecendo um contraste marcante com o ambiente mais pálido e desolado do inverno.
              </li>
              <li>
                <strong>Cores Metálicas e Frias:</strong> Detalhes metálicos, como prata e aço, também são frequentemente incorporados. Essas cores adicionam um brilho sutil e uma sensação de frescor, imitando o brilho dos cristais de gelo e da neve.
              </li>
              <li>
                <strong>Tons de Terra Frios:</strong> Cores terrosas mais frias, como o marrom-escuro e o verde musgo, são escolhidas para refletir a natureza invernal e criar um contraste acolhedor com os tons mais frios e brilhantes.
              </li>
              <li>
                <strong>Ausência de Cores Quentes:</strong> Diferente das cartelas de cores de outras estações, como o verão ou a primavera, que frequentemente apresentam tons quentes e vibrantes, a cartela de inverno tende a evitar cores quentes como o vermelho e o laranja, que são mais associadas ao calor e à energia.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] py-24 overflow-x-clip">
        <div className="container">
          <div className="max-w-[720px] mx-auto">
            <h2 className="text-center text-3xl md:text-[54px] md:leading-[60px] font-bold tracking-tighter bg-gradient-to-b from-black via-gray-700 to-teal-800 text-transparent bg-clip-text">
              Verão
            </h2>
            <p className="text-center text-lg leading-[28px] font-bold tracking-tight text-[#333333] mt-5">
              Sendo completamente fria, mas apagada, essa cartela tem cores mais claras (porque o azul inerentemente escuro foi misturado com amarelo claro, fazendo as cores se tornarem mais apagadas e claras). É por isso que encontramos muitos tons no Verão.
              A cartela de cores de verão é uma seleção de cores que captura a vivacidade e a energia da estação quente. Essas cores são escolhidas para refletir o espírito alegre e vibrante do verão, trazendo frescor e dinamismo.
            </p>
          </div>
          <div className="max-w-[720px] mx-auto mt-16">
            <h3 className="text-2xl md:text-3xl font-semibold text-[#333333] mb-6">
              Características da Cartela de Cores de Verão
            </h3>
            <ul className="list-disc text-justify md:text-left list-inside mb-6 text-[#333333] text-lg leading-[28px]">
              <li>
                <strong>Tons Vibrantes e Alegres:</strong> A cartela de verão é conhecida por suas cores vivas e brilhantes, como o amarelo, laranja, azul-turquesa e verde-limão. Essas cores evocam a luz do sol e a energia das atividades ao ar livre.
              </li>
              <li>
                <strong>Cores Claras e Pastéis:</strong> Tons mais suaves e refrescantes, como o rosa claro, azul bebê e lavanda, também fazem parte da cartela. Essas cores trazem uma sensação de leveza e frescor, perfeitas para os dias quentes.
              </li>
              <li>
                <strong>Cores Neutras Claras:</strong> Cores neutras, como branco e bege, são usadas para criar contrastes sutis e manter o visual leve e arejado.
              </li>
              <li>
                <strong>Cores que Lembram a Natureza:</strong> Cores que remetem à natureza, como verde-água e azul celeste, são comuns, refletindo o mar, o céu e o verde das plantas.
              </li>
              <li>
                <strong>Evita Cores Escuras e Pesadas:</strong> Ao contrário das cores de inverno, a cartela de verão evita tons escuros e pesados como preto e marrom-escuro, que são mais associados ao frio.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] py-24 overflow-x-clip">
        <div className="container">
          <div className="max-w-[720px] mx-auto">
            <h2 className="text-center text-3xl md:text-[54px] md:leading-[60px] font-bold tracking-tighter bg-gradient-to-b from-black via-gray-700 to-teal-800 text-transparent bg-clip-text">
              Primavera
            </h2>
            <p className="text-center text-lg leading-[28px] font-bold tracking-tight text-[#333333] mt-5">
              Sendo completamente quente e brilhante, essa cartela possui muitas cores mais claras (porque o amarelo é quente e claro). É por isso que encontramos muitas tonalidades na Primavera.
              A cartela de cores de primavera é uma seleção de tons que captura a leveza e a renovação características da estação. Com a chegada da primavera, a natureza floresce e os dias se tornam mais claros e vibrantes, e isso se reflete nas cores escolhidas para a moda, decoração e outros aspectos do design.
            </p>
          </div>
          <div className="max-w-[720px] mx-auto mt-16">
            <h3 className="text-2xl md:text-3xl font-semibold text-[#333333] mb-6">
              Características da Cartela de Cores de Primavera
            </h3>
            <ul className="list-disc text-justify md:text-left list-inside mb-6 text-[#333333] text-lg leading-[28px]">
              <li>
                <strong>Tons Suaves e Pastéis:</strong> A primavera é marcada por cores suaves e delicadas, como rosa claro, azul bebê, lavanda e verde menta. Esses tons são inspirados nas flores e no céu limpo, trazendo uma sensação de frescor e renovação.
              </li>
              <li>
                <strong>Cores Vibrantes e Alegres:</strong> Além dos pastéis, a cartela de primavera inclui cores mais vivas e alegres, como amarelo limão e coral. Essas cores trazem um toque de energia e otimismo, refletindo a vitalidade da estação.
              </li>
              <li>
                <strong>Cores Neutras Claras:</strong> Tons neutros em tons claros, como branco e bege, são usados para criar uma base limpa e arejada, que complementa as cores mais vibrantes e suaves da cartela.
              </li>
              <li>
                <strong>Inspiração Natural:</strong> Muitas das cores da primavera são inspiradas na natureza, como o verde claro das folhas novas e o azul do céu primaveril. Essas cores ajudam a criar um ambiente que celebra a renovação e o crescimento.
              </li>
              <li>
                <strong>Evita Cores Escuras e Pesadas:</strong> A cartela de primavera evita tons escuros e pesados, como preto e marrom escuro, que são mais associados ao outono e ao inverno. O foco está em criar um visual leve e arejado.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] py-24 overflow-x-clip">
        <div className="container">
          <div className="max-w-[720px] mx-auto">
            <h2 className="text-center text-3xl md:text-[54px] md:leading-[60px] font-bold tracking-tighter bg-gradient-to-b from-black via-gray-700 to-teal-800 text-transparent bg-clip-text">
              Outono
            </h2>
            <p className="text-center text-lg leading-[28px] font-bold tracking-tight text-[#333333] mt-5">
              Sendo completamente quente, mas apagado, essa cartela traz cores mais escuras (porque o amarelo claro foi misturado com azul escuro, causando as cores a se tornarem mais turvas e escuras). É por isso que encontramos tons e sombras no Outono.
              A cartela de cores de outono é uma seleção de cores que reflete a transição da estação quente para o clima mais fresco, capturando a essência do outono com tons quentes e terrosos. Essas cores são escolhidas para evocar a sensação acolhedora e a beleza natural da estação.
            </p>
          </div>
          <div className="max-w-[720px] mx-auto mt-16">
            <h3 className="text-2xl md:text-3xl font-semibold text-[#333333] mb-6">
              Características da Cartela de Cores de Outono
            </h3>
            <ul className="list-disc text-justify md:text-left list-inside mb-6 text-[#333333] text-lg leading-[28px]">
              <li>
                <strong>Tons Quentes e Terrosos:</strong> A cartela de outono é marcada por cores como laranja queimado, marrom, cobre e terracota. Esses tons imitam as folhas secas e a terra, trazendo uma sensação de calor e conforto.
              </li>
              <li>
                <strong>Cores Ricas e Profundas:</strong> Tons mais profundos, como vinho, verde-oliva e mostarda, são comuns. Eles adicionam uma sensação de sofisticação e riqueza, refletindo a abundância e a profundidade da estação.
              </li>
              <li>
                <strong>Neutros Quentes:</strong> Cores neutras em tons quentes, como bege, creme e cinza-escuro, são usadas para criar uma base versátil que complementa as cores mais vibrantes da cartela de outono.
              </li>
              <li>
                <strong>Cores Inspiradas na Natureza:</strong> Além dos tons terrosos, a cartela inclui cores que lembram a natureza, como verde musgo e marrom avermelhado, evocando a paisagem outonal.
              </li>
              <li>
                <strong>Evita Cores Frias e Brilhantes:</strong> Ao contrário das cores de verão e inverno, a cartela de outono evita tons frios e brilhantes como azul claro e rosa choque, focando em cores que oferecem uma sensação de calor e acolhimento.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PalettesContent;
