import React from 'react';
import { Link } from 'react-router-dom';
import polarizedCircleImage from '../assets/polarized-circle.png'; // Ensure the path is correct

export const Hero: React.FC = () => {
  // const handleScrollToShowcase = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //   event.preventDefault();
  //   const showcaseElement = document.getElementById('showcase');
  //   if (showcaseElement) {
  //     showcaseElement.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  return (
    <section className="pt-8 pb-20 md:pt-3 md:pb-10 bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] overflow-x-clip">
      <div className="container">
        <div className="md:flex">
          <div className="md:w-[540px]">
            <h1 className="text-5xl md:text-7xl font-bold tracking-tighter bg-gradient-to-b from-black to-teal-700 text-transparent bg-clip-text md:mt-16 mt-4">
              Destaque sua beleza natural
            </h1>
            <p className="text-xl text-[#333333] tracking-tight mt-8">
              Destaque sua beleza natural com o Guia das Cores. Descubra sua cartela online e descubra cores mais te valorizam. Se destaque em todas as ocasiões.
            </p>
            <div className="flex items-center mt-12">
              <Link to="/analise-cor" className="btn btn-primary text-xl">Descubra sua cartela</Link>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:h-[540px] md:flex-1 relative">
            <img
              src={polarizedCircleImage}
              alt="Polarized Circle"
              className="md:absolute md:h-full md:w-auto md:max-w-none md:-left-6"
            />
          </div>
        </div>
      </div>
    </section>
  );
};