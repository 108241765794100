// src/pages/ContentPage.tsx
import React from 'react';
import { Header } from '../sections/Header';
import { Footer } from '../sections/Footer';

const ColorTheory: React.FC = () => {
  return (
    <>
      <Header />
      <section className="bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] py-24 overflow-x-clip">
        <div className="container">
          <div className="max-w-[720px] mx-auto">
            <h2 className="text-center text-3xl md:text-[54px] md:leading-[60px] font-bold tracking-tighter bg-gradient-to-b from-black via-gray-700 to-teal-800 text-transparent bg-clip-text">
              Entenda as Cores
            </h2>
            <p className="text-center text-lg leading-[28px] font-bold tracking-tight text-[#333333] mt-5">
              Já vestiu uma roupa e recebeu vários elogios sobre como aquela cor caía bem em você?
            </p>
            <p className="text-center text-lg leading-[28px] font-bold tracking-tight text-[#333333] mt-5">
                Ou colocou uma roupa que parecia linda na vitrine e na modelo, mas quando você vestiu não soube explicar por que, mas algo não te agradou?
            </p>
            <p className="text-center text-lg leading-[28px] font-bold tracking-tight text-[#333333] mt-5">
              Tudo isso tem uma explicação, e é o que a teoria das cores explica.
            </p>
            <p className="text-lg text-justify md:text-left leading-[28px] text-[#333333] mt-10">
              Usar as cores certas vai te auxiliar a evidenciar melhor a sua beleza e fazer você brilhar!
              A análise de cores vai te ajudar a entender melhor sobre esse universo, basta clicar em “descubra a sua” para descobrir as cores mais te valorizam (e as que você deveria evitar)
            </p>
          </div>
          <div className="max-w-[720px] mx-auto mt-8">
            <h3 className="text-2xl md:text-3xl font-semibold text-[#333333] mb-6">
              Sobre a Teoria das Cores
            </h3>
            <p className="text-lg text-justify md:text-left leading-[28px] text-[#333333] mb-6">
              Para entender um pouco mais sobre a análise de cores, temos três aspectos da dimensão das cores. São eles:
            </p>
            <h4 className="text-xl font-semibold text-[#333333] mb-4">
              Temperatura (subtom)
            </h4>
            <p className="text-lg text-justify md:text-left leading-[28px] text-[#333333] mb-6">
              Por vezes ouvimos as pessoas falarem sobre cores quentes e cores frias. Mas o que isso significa na prática?
              Ser quente ou frio é uma característica da cor que conhecemos por temperatura. Não é um consenso universal e não quer dizer que todo azul vai ser uma cor fria e todo amarelo vai ser uma cor quente.
              A prática de identificar a temperatura das cores depende de você treinar o seu olhar. De toda forma, pense nas brincadeiras que alguma vez já fizemos de misturar tintas e formar outras cores.
              Um consenso que podemos adotar é que as cores que possuem mais amarelo adicionado são classificadas como quentes. Portanto, quanto mais amarelo uma cor tiver (quanto mais amarelo você misturar em uma cor), mais quente ela se tornará.
              Por outro lado, quanto mais azul uma cor tiver, mais fria ela se tornará.
              Se o subtom de uma cor é imperceptível, ela é uma cor neutra - nem quente nem fria. Exemplos são verde e vermelho: enquanto o verde puro consiste de amarelo e azul em partes iguais, o vermelho puro não contém nem azul nem amarelo.
            </p>
            <h4 className="text-xl font-semibold text-[#333333] mb-4">
              Profundidade
            </h4>
            <p className="text-lg text-justify md:text-left leading-[28px] text-[#333333] mb-6">
              É a característica que vai nos dizer quão clara ou escura uma cor é.
              Pensando ainda na atividade de misturar tinta, quanto mais branco é adicionado, mais clara a cor se torna, e chamamos de tonalidade. Quanto mais preto é adicionado mais escura a cor se torna, e chamamos de sombra.
            </p>
            <h4 className="text-xl font-semibold text-[#333333] mb-4">
              Saturação
            </h4>
            <p className="text-lg text-justify md:text-left leading-[28px] text-[#333333] mb-6">
              Define o quão brilhante (clara) ou apagada uma cor é.
              Cores claras e brilhantes são as cores que (na nossa brincadeira de misturar cores) não adicionamos o cinza. Quanto mais cinza a gente adiciona, menos saturada a cor é.
            </p>
            <h3 className="text-2xl md:text-3xl font-semibold text-[#333333] mb-6">
              Análise de Cores Dividida em Estações do Ano
            </h3>
            <p className="text-lg text-justify md:text-left leading-[28px] text-[#333333] mb-6">
              O livro “Color Me Beautiful” de Carole Jackson, datado da década de 1980, foi o que popularizou a aplicação das quatro estações do ano à escolha de cores.
              O livro determina a coloração baseado em:
              <ul className="list-disc list-inside ml-6 mb-6">
                <li>Se é quente ou frio (temperatura);</li>
                <li>Se é clara ou escura (profundidade).</li>
              </ul>
              De acordo com Carole, o tipo de estação vai depender, portanto:
              <ol className="list-decimal list-inside ml-6 mb-6">
                <li>Do subtom da sua pele, cabelo e olhos - se é mais quente/dourado, ou mais frio/acinzentado</li>
                <li>Do quão claro ou escuro é sua coloração geral - principalmente seu cabelo (importante dizer que sempre estamos falando do cabelo na cor natural/virgem e da pele sem maquiagem)</li>
              </ol>
              As estações vão se basear nas quatro variações dos dois pontos acima (subtom de pele e profundidade das suas cores). Se a cor natural do seu cabelo for mais clara, você deve ser uma Primavera ou Verão; se for mais escura, Outono ou Inverno.
              Se sua pele e cabelo tiverem um subtom quente, você é primavera ou outono.
              Essa é apenas uma introdução, não uma fórmula matemática. As cores servem para libertar e dar um direcionamento mais fiel para que a gente ressalte o que existe de melhor na gente, não um guia de proibições.
            </p>
            <h4 className="text-xl font-semibold text-[#333333] mb-4">
              As Doze Cores
            </h4>
            <p className="text-lg text-justify md:text-left leading-[28px] text-[#333333] mb-6">
              A verdade é que ninguém se encaixa 100% em uma cartela específica. Olhando para as cores de primavera e inverno, elas são claras e brilhantes. Olhando para as cartelas de verão e outono, elas são mais suaves e apagadas. Ao adicionar a saturação a essa análise, foi criado um modelo de doze estações que tem cores mais precisas.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ColorTheory;
