import React, { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { SeasonColorsClient } from '../api/getColorSeason';
import { ColorPaletteResponse } from '../api/types';
import ColorPicker from './ColorPicker';

export const PaletteAnalyzer: React.FC = () => {
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [colors, setColors] = useState<string[]>(['rgb(255, 255, 255)', 'rgb(255, 255, 255)']);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [result, setResult] = useState<ColorPaletteResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // Determine if both hair and skin colors have been selected
    const isReadyToSubmit = colors[0] !== 'rgb(255, 255, 255)' && colors[1] !== 'rgb(255, 255, 255)';

    const rgbStringToNumbers = (rgbString: string) => {
        const match = rgbString.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
        if (match) {
            return {
                r: parseInt(match[1]),
                g: parseInt(match[2]),
                b: parseInt(match[3])
            };
        } else {
            return null;
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file && file.type.startsWith("image/")) {
            const url = URL.createObjectURL(file);
            const img = new Image();
            img.src = url;
            img.onload = () => setImage(img);
        }
    };

    const handleColorPick = (pickedColors: string[]) => {
        setColors(pickedColors);
    };

    const handleSubmit = async () => {
        if (!isReadyToSubmit) {
            alert('Por favor, selecione as cores dos seus cabelos e da sua pele antes de enviar.');
            return;
        }

        if (!image) return;

        setLoading(true);
        setSubmitted(true);

        const rgbColors = rgbStringToNumbers(colors[1]);
        if (rgbColors) {
            const content = await SeasonColorsClient.fetchSeasonColors(rgbColors.r, rgbColors.g, rgbColors.b);
            setTimeout(() => {
                setResult(content);
                setLoading(false);
            }, 3000);
        }
    };

    const handleTryAgain = () => {
        setSubmitted(false);
        setResult(null);
        setImage(null);
        setColors(['rgb(255, 255, 255)', 'rgb(255, 255, 255)']);
    };

    return (
        <div className="container w-full h-full">
                {!submitted ? (
                    <>
                        {
                            !image && (
                                <div className="flex btn-call-to-action py-1 mt-12 ">
                                    <label 
                                        htmlFor="file-upload" 
                                        className="flex items-center justify-center text-white h-14 text-xl rounded-xl font-bold w-full cursor-pointer"
                                    >
                                        Selecione sua foto
                                    </label>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        className="hidden"
                                    />
                                </div>
                            )
                        }
                        {image && (
                            <>
                                <ColorPicker image={image} onColorPick={handleColorPick} />
                                <div className="flex justify-center items-center mt-10">
                                    <button
                                    onClick={handleSubmit}
                                    className={`btn btn-call-to-action ${!isReadyToSubmit ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={loading || !isReadyToSubmit}
                                    >
                                    {loading ? 'Carregando...' : 'Descubra sua cartela de cores'}
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className="result-box text-center py-8">
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <ThreeDots color="#000" height={80} width={80} />
                            </div>
                        ) : (
                            <>
                                <p className="text-xl mb-4">
                                    De acordo com a nossa análise, a sua cartela de cores é <strong>{result?.season}</strong>. Aqui estão algumas dicas de cores para se usar e evitar:
                                </p>
                                <div className="space-y-8">
                                    <div className="p-4 border rounded-lg bg-white shadow-md">
                                        <h3 className="text-lg font-semibold mb-2">Top 20 Cores para Usar</h3>
                                        <div className="grid grid-cols-5 gap-2">
                                            {result?.top_twenty_colors.map((color, index) => (
                                                <div key={`${color}-${index}`} className="flex items-center justify-center">
                                                    <span className="w-16 h-16 inline-block" style={{ backgroundColor: color.hex }}></span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="p-4 border rounded-lg bg-white shadow-md">
                                        <h3 className="text-lg font-semibold mb-2">Top 10 Cores para Evitar</h3>
                                        <div className="grid grid-cols-5 gap-2">
                                            {result?.top_ten_colors_to_avoid.map((color, index) => (
                                                <div key={`${color}-${index}`} className="flex items-center justify-center">
                                                    <span className="w-16 h-16 inline-block" style={{ backgroundColor: color.hex }}></span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-10">
                                    <button onClick={handleTryAgain} className="disabled:opacity-50 btn btn-call-to-action">Refaça o teste</button>
                                </div>
                                <div className="mt-12 bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] p-6 rounded-lg">
                                    <h3 className="text-xl font-semibold mb-4">Dicas para obter os melhores resultados</h3>
                                    <ul className="list-disc list-inside mb-4">
                                        <li>Utilize uma foto em luz natural</li>
                                        <li>Ao selecionar uma cor no seu rosto, clique em uma parte que não esteja diretamente iluminada</li>
                                        <li>Experimente quantas vezes achar necessário</li>
                                    </ul>
                                    <p className="text-sm text-gray-600">
                                        <strong>Aviso:</strong> Dependendo da foto os resultados podem variar. Portanto, divirta-se experimentando com diferentes fotos.
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                )}
        </div>
    );
};
