import React, { useRef, useState, useEffect } from 'react';

interface ColorPickerProps {
  image: HTMLImageElement; // Prop to receive the image object
  onColorPick?: (colors: string[]) => void; // Callback to pass colors to parent
}

const ColorPicker: React.FC<ColorPickerProps> = ({ image, onColorPick }) => {
  const [colors, setColors] = useState<string[]>(['rgb(255, 255, 255)', 'rgb(255, 255, 255)']);
  const colorNames = ['Cabelo', 'Pele'];
  const [clickCount, setClickCount] = useState<number>(1); // Track click count for color setting
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number } | null>(null);
  const [borderColor, setBorderColor] = useState<string>('rgba(255, 255, 255, 0.3)'); // Default border color
  const [isCursorOver, setIsCursorOver] = useState<boolean>(false); // Track if cursor is over canvas
  const [selectedColorIndex, setSelectedColorIndex] = useState<number | null>(0); // Track selected color index
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (image && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d', { willReadFrequently: true });

      if (context) {
        const targetWidth = 540;
        const maxHeight = 700;
        const aspectRatio = image.width / image.height;

        let width = targetWidth;
        let height = targetWidth / aspectRatio;

        if (height > maxHeight) {
          height = maxHeight;
          width = maxHeight * aspectRatio;
        }

        canvas.width = width;
        canvas.height = height;

        context.drawImage(image, 0, 0, width, height);
      }
    }
  }, [image]);

  useEffect(() => {
    if (onColorPick) {
      onColorPick(colors);
    }
  }, [colors, onColorPick]);

  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d', { willReadFrequently: true });

      if (context) {
        const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
        const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY;

        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        const x = (clientX - rect.left) * scaleX;
        const y = (clientY - rect.top) * scaleY;

        const pixel = context.getImageData(x, y, 1, 1).data;
        const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;

        setClickCount(prevClickCount => {
          const newClickCount = (prevClickCount + 1) % 2; // Loop through 0, 1, 2
          setColors(prevColors => {
            const updatedColors = [...prevColors];
            updatedColors[newClickCount] = color; // Update the color based on click count
            return updatedColors;
          });
          setSelectedColorIndex((newClickCount + 1) % 2); // Update selected color index
          return newClickCount; // Update click count
        });
      }
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    handleMouseMove(event as unknown as React.MouseEvent<HTMLCanvasElement>);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d', { willReadFrequently: true });
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;

      const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
      const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY;

      const x = (clientX - rect.left) * scaleX;
      const y = (clientY - rect.top) * scaleY;

      if (context) {
        const pixel = context.getImageData(x, y, 1, 1).data;
        const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
        setBorderColor(color);
      }

      setCursorPosition({ x: clientX - rect.left, y: clientY - rect.top });
      setIsCursorOver(true);
    }
  };

  const handleMouseEnter = () => {
    setIsCursorOver(true);
  };

  const handleMouseLeave = () => {
    setIsCursorOver(false);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    handleMouseMove(event);
  };

  const handleTouchEnd = () => {
    setIsCursorOver(false);
  };

  return (
    <div className="flex flex-col md:flex-row items-center space-y-4 p-4 w-full max-h-[700px] max-w-[540px] mx-auto">
      <div ref={containerRef} className="relative" style={{ position: 'relative', width: '100%', height: 'auto' }}>
        <canvas
          ref={canvasRef}
          className="border border-black cursor-pointer"
          onClick={handleCanvasClick}
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            imageRendering: 'crisp-edges' // Helps maintain sharpness
          }}
        />
        {isCursorOver && cursorPosition && (
          <div
            className="absolute border-2 rounded-full pointer-events-none w-36 h-36"
            style={{
              left: cursorPosition.x, // Center the circle on cursor
              top: cursorPosition.y,
              border: '12px solid',
              transform: 'translate(-50%, -50%)',
              borderColor: borderColor,
              backgroundColor: 'rgba(255, 255, 255, 0)' // Transparent background
            }}
          />
        )}
      </div>
      <div className="md:px-8">
        <div className="flex flex-row md:flex-col md:ml-12 space-x-8 md:space-x-0 md:space-y-4">
          {colors.map((color, index) => (
            <div
              key={index}
              className={`flex flex-col items-center relative ${selectedColorIndex === index ? 'scale-110 shadow-lg' : ''}`} // Add scale and shadow to selected color
              style={{ transition: 'transform 0.3s, box-shadow 0.3s' }} // Smooth transition
            >
              <p className="text-sm font-medium mb-2">{`${colorNames[index]}`}</p>
              <div
                style={{ backgroundColor: color }}
                className={`w-12 h-12 rounded-full border border-black ${selectedColorIndex === index ? 'border-blue-500' : ''}`} // Change border color for selected item
              />
              {selectedColorIndex === index && (
                <div
                  className="absolute inset-0 border-2 border-blue-500 rounded-full pointer-events-none"
                  style={{ transform: 'scale(1.2)', zIndex: -1 }} // Add a larger border effect
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
