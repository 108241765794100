import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import Logo from '../assets/logo-mycolorai.png'; // Adjust path as needed
import { ReactComponent as MenuIcon } from '../assets/menu.svg'; // Adjust path as needed

export const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="z-20">
      <div className="py-5">
        <div className="container">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <img src={Logo} alt="Logo" height={40} width={40} className="rounded-lg" />
              <h1 className="text-3xl tracking-tighter bg-gradient-to-b from-black to-teal-700 text-transparent bg-clip-text font-bold">
                Guia das Cores
              </h1>
            </div>
            <MenuIcon
              className="h-5 w-5 md:hidden cursor-pointer"
              onClick={toggleMenu}
            />
          </div>
          <svg height="2" width="100%" className="my-4 hidden md:block">
            <line x1="0" y1="0" x2="100%" y2="0" style={{ stroke: 'gray', strokeWidth: 2 }} />
          </svg>
          <div className="flex items-center justify-center mt-6">
            <nav className="hidden md:flex gap-6 text-black/80 items-center justify-center">
              <NavLink
                to="/inicio"
                className={({ isActive }) =>
                  isActive ? 'text-highlight' : ''
                }
              >
                Começe aqui
              </NavLink>
              <NavLink
                to="/analise-cor"
                className={({ isActive }) =>
                  isActive ? 'text-highlight' : ''
                }
              >
                Descubra sua cartela
              </NavLink>
              <NavLink
                to="/cartelas"
                className={({ isActive }) =>
                  isActive ? 'text-highlight' : ''
                }
              >
                Entenda sua cartela
              </NavLink>
              <NavLink
                to="/entenda"
                className={({ isActive }) =>
                  isActive ? 'text-highlight' : ''
                }
              >
                Entenda as cores
              </NavLink>
            </nav>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-500 ease-in-out md:hidden z-50`}
      >
        <div className="p-4">
        <button onClick={toggleMenu} className="text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <nav className="flex flex-col p-8 items-center">
          <NavLink
            to="/inicio"
            className={({ isActive }) =>
              isActive ? 'text-highlight mt-4' : 'text-black mt-4'
            }
            onClick={toggleMenu}
          >
            Comece aqui
          </NavLink>
          <NavLink
            to="/analise-cor"
            className={({ isActive }) =>
              isActive ? 'text-highlight mt-4' : 'text-black mt-4'
            }
            onClick={toggleMenu}
          >
            Descubra sua cartela
          </NavLink>
          <NavLink
            to="/cartelas"
            className={({ isActive }) =>
              isActive ? 'text-highlight mt-4' : 'text-black mt-4'
            }
            onClick={toggleMenu}
          >
            Entenda sua cartela
          </NavLink>
          <NavLink
            to="/entenda"
            className={({ isActive }) =>
              isActive ? 'text-highlight mt-4' : 'text-black mt-4'
            }
            onClick={toggleMenu}
          >
            Entenda as cores
          </NavLink>
        </nav>
      </div>
    </header>
  );
};
