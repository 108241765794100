import React from "react";
import { PaletteAnalyzer } from "../component/PaletteAnalyzer";

export const CallToAction: React.FC = () => {
    return (
        <section id="call-to-action" className="pt-8 pb-20 md:pt-5 md:pb-10 bg-gradient-to-b from-[#FFFFFF] to-[#F2F9FF] py-24 overflow-x-clip">
            <div className="container">
                <div className="max-w-[720px] mx-auto">
                    <h2 className="text-4xl md:text-5xl font-bold tracking-tighter text-center bg-gradient-to-b from-black to-teal-700 text-transparent bg-clip-text mt-6">
                        Descubra sua cartela de cores em apenas três passos.
                    </h2>
                    <div className="flex flex-col mt-8 space-y-8">
                        <div className="flex items-start space-x-6">
                            <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-gray-800 text-white text-xl font-bold rounded-full">
                                1
                            </div>
                            <div className="flex-1">
                                <p className="text-lg font-semibold text-gray-600">Escolha uma boa foto</p>
                                <p className="text-gray-700 mt-2">Escolha uma foto clara, preferencialmente em luz natural, sem sombras ou maquiagem</p>
                            </div>
                        </div>
                        <div className="flex items-start space-x-6">
                            <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-gray-800 text-white text-xl font-bold rounded-full">
                                2
                            </div>
                            <div className="flex-1">
                                <p className="text-lg font-semibold text-gray-600">Selecione a cor dos seus cabelos e pele</p>
                                <p className="text-gray-700 mt-2">Basta selecionar um ponto que reflita bem a cor natural do seu cabelo, sem pinturas, ou o mais próximo da raiz. Em seguida selecione um ponto que reflita bem a cor natural da sua pele sem maquiagens</p>
                            </div>
                        </div>
                        <div className="flex items-start space-x-6">
                            <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-gray-800 text-white text-xl font-bold rounded-full">
                                3
                            </div>
                            <div className="flex-1">
                                <p className="text-lg font-semibold text-gray-600">Faça a análise de cor</p>
                                <p className="text-gray-700 mt-2">Tudo pronto! Clique no botão e descubra sua cartela de cores ideal</p>
                            </div>
                        </div>
                    </div>
                    <PaletteAnalyzer />
                </div>
            </div>
        </section>
    );
};
